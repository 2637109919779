
import { Options, Vue } from 'vue-class-component';
import Step1 from '../components/step1.vue';
import Step2 from '../components/step2.vue';
import Step3 from '../components/step3.vue';
import store from '@/store';
import * as VM from '@/viewModel';
import * as OM from '@/model';
import { BaseCommunityClient, CommunityClient } from '@/services/Services';

@Options({})
export default class CreateCommunity extends Vue {

    created() {
        store.state.createCommunityVm = new VM.CreateCommunityVm();
    }

    currentStep: number = 1;
    get step() {
        if(this.currentStep == 1){
            return Step1;
        } else if(this.currentStep == 2){
            return Step2;
        } else if(this.currentStep == 3){
            return Step3;
        }
    }

    get canNext() {
        if(this.currentStep == 1){
            return store.state.createCommunityVm.title && store.state.createCommunityVm.description && store.state.createCommunityVm.logo && store.state.createCommunityVm.backgroundImage;
        } else if(this.currentStep == 2) {
            return store.state.createCommunityVm.category && store.state.createCommunityVm.tags.length > 0;
        } else if(this.currentStep == 3) {
            return true;
        }
        // else if(this.$route.name == 'community_create_step4') {
        //     return store.state.createCommunityVm.title && store.state.createCommunityVm.description && store.state.createCommunityVm.logo;
        // }
    }

    back() {
        if(this.currentStep > 1){
            this.currentStep--;
        } else {
            this.$emit('close');
        }
    }

    next() {
        if(this.currentStep == 1){
            this.currentStep = 2;
        } else if(this.currentStep == 2) {
            this.currentStep = 3
        } else if(this.currentStep == 3) {
            store.state.globalSpinner++;
            CommunityClient.createCommunity(store.state.createCommunityVm)
            .then(x => {
                Promise.all([
                    BaseCommunityClient.updateBackgroundImage(x.identifier, store.state.createCommunityVm.backgroundImage),
                    BaseCommunityClient.updateLogoImage(x.identifier, store.state.createCommunityVm.logo),
                    BaseCommunityClient.addGalleryImages(x.identifier, store.state.createCommunityVm.gallery)
                ]).then( y => {
                    this.$router.push('/communities/' + x.slug);
                    this.$opModal.closeAll();
                })
            })
            .catch(x => {
            })
            .finally(() => store.state.globalSpinner--);
        }
    }
}

