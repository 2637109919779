
import { mixins, Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import UoseWriter from '@/components/uoseWriter.vue';
import WithModel from '@/components/mixins/withModel';

@Options({
    components: {
        UoseWriter
    }
})
export default class UoseWriterTextarea extends mixins(WithModel) {

    @Prop() titleText: string;
    @Prop() titleKey: string;

    @Prop() reset: number;
    @Prop() placeholderText: string;
    @Prop() placeholderKey: string;
    @Prop() minHeight: number;
    @Prop() maxHeight: number;

}

