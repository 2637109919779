
import { Options, Vue } from 'vue-class-component';
import { AppConfigClient } from '@/services/Services';
import Removable from '@/components/removable.vue';
import store from '@/store';
import * as OM from '@/model';

@Options({
    components: {
        Removable
    }
})
export default class Step2 extends Vue {

    loaded: boolean = false;
    categories: OM.CategoryConfigVm[] = [];
    newTag: string = "";

    created() {
        AppConfigClient.getCategories()
        .then(x => {
            this.categories = x;
        });
    }

    addTag() {
        var newTag = this.newTag.trim();
        var elementInLower = store.state.createCommunityVm.tags.find(x => x.toLowerCase() == this.newTag.toLowerCase())

        if(!newTag || store.state.createCommunityVm.tags.includes(this.newTag) == true 
        || elementInLower)

            return

        store.state.createCommunityVm.tags.push(this.newTag);
        this.newTag = "";
        (<any>this.$refs.tagInput).focus();
        
    }
    removeTag(index: number) {
        store.state.createCommunityVm.tags.splice(index, 1);
    }

}

