
import { Options, Vue } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import store from '@/store';
import * as OM from '@/model';
import UoseWriterTextarea from '@/components/uoseWriterTextarea.vue';


@Options({
    components:{
        UoseWriterTextarea
    }
})

export default class Step1 extends Vue {

    communityPublic = OM.CommunityType.Public;
    communityOnRequest = OM.CommunityType.OnRequest;
    communityClose = OM.CommunityType.Close;

    logoPreview: any = null;



    @Watch('$store.state.createCommunityVm.logo')
    onLogoChange(next, prev){
        this.makeLogoPreview();
    }

    makeLogoPreview() {
        if(store.state.createCommunityVm.logo) {
            if(typeof store.state.createCommunityVm.logo == typeof "") {
                this.logoPreview = store.state.createCommunityVm.logo;
            } else {
                var reader = new FileReader();
                reader.onload = (e: any) => {
                    this.logoPreview = e.target.result;
                }
                reader.readAsDataURL(store.state.createCommunityVm.logo);
            }
        }
    }
    
    
    backgroundPreview: any = null;
    @Watch('$store.state.createCommunityVm.backgroundImage')
    onBackgroundChange(next, prev){
        this.makeBgPreview();
    }
    makeBgPreview(){
        if(store.state.createCommunityVm.backgroundImage){
            if(typeof store.state.createCommunityVm.backgroundImage == typeof ""){
                this.backgroundPreview = store.state.createCommunityVm.backgroundImage;
            } else {
                var reader = new FileReader();
                reader.onload = (e: any) => {
                    this.backgroundPreview = e.target.result;
                }
                reader.readAsDataURL(store.state.createCommunityVm.backgroundImage);
            }
        }
    }

    created(){

        console.log("oggetto",store.state.createCommunityVm)
        console.log("back", store.state.createCommunityVm.backgroundImage)
        console.log("logo", store.state.createCommunityVm.logo)
        console.log("galleria", store.state.createCommunityVm.gallery)

        this.makeLogoPreview();
        this.makeBgPreview();
    }

}

