
import { Options, Vue } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import RemovableImage from '@/components/removableImage.vue';
import store from '@/store';

@Options({
    components: {
        RemovableImage
    }
})
export default class Step3 extends Vue {

    loaded: boolean = false;

    tempFile: File = null;
    tempFiles: string[] = [];
    @Watch('tempFile')
    onTempFileChange(next, prev){
        if(this.tempFile){
            if(typeof this.tempFile == "string"){
                this.tempFiles.push(this.tempFile);
                store.state.createCommunityVm.gallery.push(this.tempFile);
                this.tempFile = null;
            } else {
                store.state.createCommunityVm.gallery.push(this.tempFile);
                var reader = new FileReader();
                reader.onload = (e: any) => {
                    this.tempFiles.push(e.target.result);
                }
                reader.readAsDataURL(this.tempFile);
                this.tempFile = null;
            }
        }
    }

    removeImage(index: number){
        store.state.createCommunityVm.gallery.splice(index, 1);
        this.tempFiles.splice(index, 1);
    }

    created(){
        if(store.state.createCommunityVm.gallery.length > 0){
            store.state.createCommunityVm.gallery.forEach(x => {
                var reader = new FileReader();
                reader.onload = (e: any) => {
                    this.tempFiles.push(e.target.result);
                }
                reader.readAsDataURL(x);
            })
        }
    }

}

