import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "mt-4" }
const _hoisted_2 = { class: "mt-4" }
const _hoisted_3 = { class: "form-group squareInputContainer withLabel" }
const _hoisted_4 = { class: "form-group squareInputContainer withLabel mt-3" }
const _hoisted_5 = { class: "d-flex flex-wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_user_profile_image = _resolveComponent("user-profile-image")!
  const _component_localized_text = _resolveComponent("localized-text")!
  const _component_file_selector = _resolveComponent("file-selector")!
  const _component_uose_writer_textarea = _resolveComponent("uose-writer-textarea")!

  return (_openBlock(), _createBlock("div", null, [
    _createVNode(_component_file_selector, {
      modelValue: _ctx.$store.state.createCommunityVm.logo,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.$store.state.createCommunityVm.logo = $event)),
      accept: "image/png, image/jpeg, image/jpg"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_user_profile_image, {
          class: "profileImageRound",
          src: _ctx.logoPreview,
          key: _ctx.logoPreview
        }, null, 8, ["src"]),
        _createVNode(_component_localized_text, {
          localizedKey: "app_cambiafotologo",
          text: "Cambia foto logo",
          class: "fs_14 grey d-block text-center mt-2"
        })
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode("div", _hoisted_1, [
      _createVNode(_component_file_selector, {
        modelValue: _ctx.$store.state.createCommunityVm.backgroundImage,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.$store.state.createCommunityVm.backgroundImage = $event))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_user_profile_image, {
            class: "profileImageRound",
            src: _ctx.backgroundPreview
          }, null, 8, ["src"]),
          _createVNode(_component_localized_text, {
            localizedKey: "app_cambiafotocopertina",
            text: "Cambia foto copertina",
            class: "fs_14 grey d-block text-center mt-2"
          })
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    _createVNode("div", _hoisted_2, [
      _createVNode("div", _hoisted_3, [
        _createVNode("label", null, [
          _createVNode(_component_localized_text, {
            localizedKey: "app_nomecommunity",
            text: "Nome community",
            class: "fs_12 grey"
          })
        ]),
        _withDirectives(_createVNode("input", {
          type: "text",
          class: "genericInput squareInput",
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.$store.state.createCommunityVm.title = $event))
        }, null, 512), [
          [_vModelText, _ctx.$store.state.createCommunityVm.title]
        ])
      ]),
      _createVNode("div", _hoisted_4, [
        _createVNode(_component_uose_writer_textarea, {
          modelValue: _ctx.$store.state.createCommunityVm.description,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (_ctx.$store.state.createCommunityVm.description = $event)),
          placeholderText: "scrivi una descrizione",
          placeholderKey: "app_scriviDescrizione",
          "max-height": 200
        }, null, 8, ["modelValue"])
      ]),
      _createVNode(_component_localized_text, {
        class: "fs_12 greyBlue d-block mt-2 mb-2",
        localizedKey: "app_visibilitacommunity",
        text: "Visibilità community"
      }),
      _createVNode("div", _hoisted_5, [
        _createVNode("div", {
          class: ["filterTab", _ctx.$store.state.createCommunityVm.communityType == _ctx.communityPublic ? 'active' : ''],
          onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$store.state.createCommunityVm.communityType = _ctx.communityPublic))
        }, [
          _createVNode(_component_localized_text, {
            class: "fs_12 d-block",
            localizedKey: "app_pubblica",
            text: "Pubblica"
          })
        ], 2),
        _createVNode("div", {
          class: ["filterTab", _ctx.$store.state.createCommunityVm.communityType == _ctx.communityOnRequest ? 'active' : ''],
          onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.$store.state.createCommunityVm.communityType = _ctx.communityOnRequest))
        }, [
          _createVNode(_component_localized_text, {
            class: "fs_12 d-block",
            localizedKey: "app_surichiesta",
            text: "Su richiesta"
          })
        ], 2),
        _createVNode("div", {
          class: ["filterTab", _ctx.$store.state.createCommunityVm.communityType == _ctx.communityClose ? 'active' : ''],
          onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.$store.state.createCommunityVm.communityType = _ctx.communityClose))
        }, [
          _createVNode(_component_localized_text, {
            class: "fs_12 d-block",
            localizedKey: "app_chiusa",
            text: "Chiusa"
          })
        ], 2)
      ])
    ])
  ]))
}